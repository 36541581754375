import {Box, Checkbox, Typography, FormControlLabel} from "@mui/material";
import InputText from "../../../components/ui/InputText";
import Select from "../../../components/ui/Select";
import Label from "../../../components/ui/InputLabel";
import CustomButton from "../../../components/ui/Button";
import useTestGeneratorController from "../../../../view-controlllers/admin/useTestGeneratorController";
import {SELECT_TITLE, SENIORITY, SKILL_TYPE, STATUS} from "../../../../data/constant/Constants";
import ErrorLabel from "../../../components/ui/ErrorLabel";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';
import CustomTypography from "../../../components/ui/Typography";
import Loading from "../../../components/ui/Loading";
import QuestionHistory from "./QuestionHistory";
import Chip from "../../../components/ui/Chip/Chip";
import {styled} from '@mui/system';
import {TextareaAutosize as BaseTextareaAutosize} from '@mui/base/TextareaAutosize';
import Divider from '@mui/material/Divider';


const blue = {
   100: '#DAECFF',
   200: '#b6daff',
   400: '#3399FF',
   500: '#007FFF',
   600: '#0072E5',
   900: '#003A75',
};

const grey = {
   50: '#F3F6F9',
   100: '#E5EAF2',
   200: '#DAE2ED',
   300: '#C7D0DD',
   400: '#B0B8C4',
   500: '#9DA8B7',
   600: '#6B7A90',
   700: '#434D5B',
   800: '#303740',
   900: '#1C2025',
};

const TextareaAutosize = styled(BaseTextareaAutosize)(
   ({theme}) => `
  box-sizing: border-box;
  width: 320px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const Form = ({params}) => {

   const {
      //questions
      setValue,
      currentQuestions,
      removeQuestion,
      handleAddQuestions,
      triggeredBy,
      generateNewAIQuestion,
      fieldsQuestion,
      //
      isAIGeneratedQuestionsEnabled,
      watch,
      skillData,
      fields,
      register,
      handleSubmit,
      errors,
      isLoading,
      isLoadingUpdate,
      isLoadingRegenerate,
      indexQuestion,
      questionHistoryIsVisible,
      indexHistory,
      skill,
      //Self assessment
      handleAppendSelfAssessment,
      handleChangeValueSelfAssessment,
      selfAssessmentValue,
      selfAssessmentFields,
      removeSelfAssessment,
      //
      onVisibleQuestionHistory,
      onDeleteExtraQuestionField,
      onRegenerateQuestion,
      onHandleSubmit,
      onDiscartChanges,
      onChangeStatusSkill,
      Controller,
      configOptions,
      control,
   } = useTestGeneratorController();

   return (
      <form onSubmit={handleSubmit(data => onHandleSubmit(data))}>
         <Box sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            flexDirection: 'column',
         }}>
            <Box display='flex'>
               <Box display='flex'>
                  <Box mt={2} mr={5}>
                     <Label
                        style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}
                        title="Interview Name*"
                     />
                     <InputText
                        size="small"
                        fullWidth={true}
                        style={{width: '350px'}}
                        {...register("name")}
                     />
                     <ErrorLabel
                        error={errors?.name}
                        message={errors.name?.message}
                     />
               </Box>

                  <Box mt={2}>
                     <Label
                        style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}
                        title="Skill"
                     />
                     <InputText
                        defaulValue={params && skill?.data?.skillName}
                        disabled={params && true}
                        size="small"
                        style={{width: '350px'}}
                        {...register("skillName")}
                     />
                     <ErrorLabel
                        error={errors?.skillName}
                        message={errors.skillName?.message}
                     />
                  </Box>
               </Box>
            </Box >

            <Box display='flex'>
               <Box display='flex'>
                  <Box mt={2} mr={5}>
                     <Label
                        style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}
                        title="Seniority*"
                     />
                     <Select
                        disabled={params && true}
                        size="small"
                        styles={{width: '350px'}}
                        data={SENIORITY}
                        defaultValue=""
                        selected={!params ? true : false}
                        title={SELECT_TITLE}
                        {...register("seniority")}
                     />
                     <ErrorLabel
                        error={errors?.seniority}
                        message={errors.seniority?.message}
                     />
                  </Box>

                  <Box mt={2}>
                     <Label
                        style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}
                        title="Skill type*"
                     />
                     <Select
                        disabled={params && true}
                        size="small"
                        data={SKILL_TYPE}
                        defaultValue=""
                        selected={!params ? true : false}
                        styles={{width: '255px'}}
                        title={SELECT_TITLE}
                        {...register("skillType")}
                     />
                     <ErrorLabel
                        error={errors?.skillType}
                        message={errors.skillType?.message}
                     />
                  </Box>
               </Box>
            </Box>

            <Box display='flex'>
               <Box mt={2} mr={5}>
                  <Label
                     style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}
                     title="Language"
                  />
                  <Select
                     defaultValue={"en"}
                     size="small"
                     styles={{width: '350px'}}
                     selected={true}
                     title="English"
                     {...register("language")}
                  />
               </Box>

               <Box
                  mb={2}
                  mt={2}
                  sx={{
                     display: 'flex',
                     justifyContent: 'space-between'
                  }}>
                  <Box>
                     <Label
                        style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}
                        title="Status"
                     />
                     <Select
                        name="active"
                        size="small"
                        styles={{width: '255px'}}
                        data={STATUS}
                        selected={true}
                        title={
                           skill?.data?.active ? 'Active' : 'Inactive'
                        }
                        onSelectChange={onChangeStatusSkill}
                     />
                  </Box>
               </Box>
            </Box>


            {/*<Box mt={2} sx={{*/}
            {/*   flex: 1*/}
            {/*}}>*/}
            {/*   <Box mt={2}>*/}
            {/*      <Label*/}
            {/*         style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}*/}
            {/*         title="Interview Name*"*/}
            {/*      />*/}
            {/*      <InputText*/}
            {/*         size="small"*/}
            {/*         fullWidth={true}*/}
            {/*         style={{width: '350px'}}*/}
            {/*         {...register("name")}*/}
            {/*      />*/}
            {/*      <ErrorLabel*/}
            {/*         error={errors?.name}*/}
            {/*         message={errors.name?.message}*/}
            {/*      />*/}
            {/*   </Box>*/}
            {/*   <Box mt={2}>*/}
            {/*      <Label*/}
            {/*         style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}*/}
            {/*         title="Seniority*"*/}
            {/*      />*/}
            {/*      <Select*/}
            {/*         disabled={params && true}*/}
            {/*         size="small"*/}
            {/*         styles={{width: '350px'}}*/}
            {/*         data={SENIORITY}*/}
            {/*         defaultValue=""*/}
            {/*         selected={!params ? true : false}*/}
            {/*         title={SELECT_TITLE}*/}
            {/*         {...register("seniority")}*/}
            {/*      />*/}
            {/*      <ErrorLabel*/}
            {/*         error={errors?.seniority}*/}
            {/*         message={errors.seniority?.message}*/}
            {/*      />*/}
            {/*   </Box>*/}
            {/*   <Box mt={2}>*/}
            {/*      <Label*/}
            {/*         style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}*/}
            {/*         title="Language"*/}
            {/*      />*/}
            {/*      <Select*/}
            {/*         defaultValue={"en"}*/}
            {/*         size="small"*/}
            {/*         styles={{width: '350px'}}*/}
            {/*         selected={true}*/}
            {/*         title="English"*/}
            {/*         {...register("language")}*/}
            {/*      />*/}
            {/*   </Box>*/}
            {/*</Box>*/}
            {/*<Box*/}
            {/*   mt={2}*/}
            {/*   sx={{flex: 1}}*/}
            {/*>*/}
            {/*   <Box mt={2}>*/}
            {/*      <Label*/}
            {/*         style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}*/}
            {/*         title="Skill"*/}
            {/*      />*/}
            {/*      <InputText*/}
            {/*         defaulValue={params && skill?.data?.skillName}*/}
            {/*         disabled={params && true}*/}
            {/*         size="small"*/}
            {/*         style={{width: '350px'}}*/}
            {/*         {...register("skillName")}*/}
            {/*      />*/}
            {/*      <ErrorLabel*/}
            {/*         error={errors?.skillName}*/}
            {/*         message={errors.skillName?.message}*/}
            {/*      />*/}
            {/*   </Box>*/}
            {/*   <Box mt={2}>*/}
            {/*      <Label*/}
            {/*         style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}*/}
            {/*         title="Skill type*"*/}
            {/*      />*/}
            {/*      <Select*/}
            {/*         disabled={params && true}*/}
            {/*         size="small"*/}
            {/*         data={SKILL_TYPE}*/}
            {/*         defaultValue=""*/}
            {/*         selected={!params ? true : false}*/}
            {/*         styles={{width: '255px'}}*/}
            {/*         title={SELECT_TITLE}*/}
            {/*         {...register("skillType")}*/}
            {/*      />*/}
            {/*      <ErrorLabel*/}
            {/*         error={errors?.skillType}*/}
            {/*         message={errors.skillType?.message}*/}
            {/*      />*/}
            {/*   </Box>*/}

            {/*   <Box*/}
            {/*      mb={2}*/}
            {/*      mt={2}*/}
            {/*      sx={{*/}
            {/*         display: 'flex',*/}
            {/*         justifyContent: 'space-between'*/}
            {/*      }}>*/}
            {/*      <Box>*/}
            {/*         <Label*/}
            {/*            style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}*/}
            {/*            title="Status"*/}
            {/*         />*/}
            {/*         <Select*/}
            {/*            name="active"*/}
            {/*            size="small"*/}
            {/*            styles={{width: '255px'}}*/}
            {/*            data={STATUS}*/}
            {/*            selected={true}*/}
            {/*            title={*/}
            {/*               skill?.data?.active ? 'Active' : 'Inactive'*/}
            {/*            }*/}
            {/*            onSelectChange={onChangeStatusSkill}*/}
            {/*         />*/}
            {/*      </Box>*/}
            {/*   </Box>*/}
            {/*</Box>*/}
            <Divider sx={{my: 2}}/>

            <Box display="flex" flexDirection="column" mt={2}>
               {configOptions?.map((option) => (
                  <Box key={option.name}>
                     {!(option.name === "questionWithIA" && params && (!skillData?.data?.questions || skillData?.data?.questions.length === 0)) && (
                        <Controller
                           name={option.name}
                           control={control}
                           render={({ field }) => (
                              <FormControlLabel
                                 control={
                                    <Checkbox
                                       {...field}
                                       checked={field.value}
                                       onChange={(e) => {
                                          field.onChange(e.target.checked);
                                          if (option.name === "questionWithIA") {
                                             setValue("questionsNumber", e.target.checked ? null : 0); // Vacío si true, 0 si false
                                          }
                                       }}
                                    />
                                 }
                                 label={option.label}
                                 sx={{
                                    "& .MuiFormControlLabel-label": {
                                       fontSize: "14px",
                                       color: "#000",
                                    },
                                 }}
                              />
                           )}
                        />
                     )}
                     {option.name === "selfAssessment" && watch("selfAssessment") && (
                        <Box mt={2} mb={6}>
                           <Label
                              style={{ fontSize: "12px", fontWeight: "bold", color: "#000" }}
                              title="Self assessment*"
                           />
                           <Box display="flex" alignItems="center">
                              <Box position="relative">
                                 <InputText
                                    size="small"
                                    style={{ width: "410px" }}
                                    value={selfAssessmentValue}
                                    onChange={handleChangeValueSelfAssessment}
                                 />
                                 <Box position="absolute">
                                    <ErrorLabel
                                       error={errors?.selfAssessmentItems}
                                       message={errors?.selfAssessmentItems?.message}
                                    />
                                 </Box>
                              </Box>
                              <Box>
                                 <CustomButton
                                    sx={{ borderRadius: 50, marginLeft: 1 }}
                                    variant="contained"
                                    fullWidth
                                    title="ADD"
                                    onPress={handleAppendSelfAssessment}
                                 />
                              </Box>
                           </Box>
                           <Box mt={1}>
                              {selfAssessmentFields.map((field, index) => (
                                 <Chip
                                    key={index}
                                    label={field.value}
                                    onDelete={() => removeSelfAssessment(index)}
                                    sx={{
                                       backgroundColor: "rgba(249, 79, 121, 0.32)",
                                       marginBottom: 1,
                                       mr: 1,
                                    }}
                                 />
                              ))}
                           </Box>
                        </Box>
                     )}
                  </Box>
               ))}
            </Box>

            {/*<Box display="flex" flexDirection="column" mt={2}>*/}
            {/*   {configOptions?.map((option) => {*/}
            {/*      // Ocultar el checkbox de "questionWithIA" bajo la condición*/}
            {/*      if (option.name === "questionWithIA" && (!isAIGeneratedQuestionsEnabled && params)) {*/}
            {/*         return null; // No renderiza el checkbox si no se cumplen las condiciones*/}
            {/*      }*/}

            {/*      return (*/}
            {/*         <Box key={option.name}>*/}
            {/*            <Controller*/}
            {/*               name={option.name}*/}
            {/*               control={control}*/}
            {/*               render={({ field }) => (*/}
            {/*                  <FormControlLabel*/}
            {/*                     control={<Checkbox {...field} checked={field.value} />}*/}
            {/*                     label={option.label}*/}
            {/*                     sx={{*/}
            {/*                        "& .MuiFormControlLabel-label": {*/}
            {/*                           fontSize: "14px",*/}
            {/*                           color: "#000",*/}
            {/*                        },*/}
            {/*                     }}*/}
            {/*                  />*/}
            {/*               )}*/}
            {/*            />*/}
            {/*            {option.name === "selfAssessment" && watch("selfAssessment") && (*/}
            {/*               <Box mt={2}>*/}
            {/*                  <Label*/}
            {/*                     style={{ fontSize: "12px", fontWeight: "bold", color: "#000" }}*/}
            {/*                     title="Self assessment*"*/}
            {/*                  />*/}
            {/*                  <Box display="flex" alignItems="center">*/}
            {/*                     <Box>*/}
            {/*                        <InputText*/}
            {/*                           size="small"*/}
            {/*                           style={{ width: "410px" }}*/}
            {/*                           value={selfAssessmentValue}*/}
            {/*                           onChange={handleChangeValueSelfAssessment}*/}
            {/*                        />*/}
            {/*                        <ErrorLabel*/}
            {/*                           error={errors?.selfAssessmentItems}*/}
            {/*                           message={errors?.selfAssessmentItems?.message}*/}
            {/*                        />*/}
            {/*                     </Box>*/}
            {/*                     <Box>*/}
            {/*                        <CustomButton*/}
            {/*                           sx={{ borderRadius: 50, marginLeft: 1 }}*/}
            {/*                           variant="contained"*/}
            {/*                           fullWidth*/}
            {/*                           title="ADD"*/}
            {/*                           onPress={handleAppendSelfAssessment}*/}
            {/*                        />*/}
            {/*                     </Box>*/}
            {/*                  </Box>*/}
            {/*                  <Box mt={1}>*/}
            {/*                     {selfAssessmentFields.map((field, index) => (*/}
            {/*                        <Chip*/}
            {/*                           key={index}*/}
            {/*                           label={field.value}*/}
            {/*                           onDelete={() => removeSelfAssessment(index)}*/}
            {/*                           sx={{*/}
            {/*                              backgroundColor: "rgba(249, 79, 121, 0.32)",*/}
            {/*                              marginBottom: 1,*/}
            {/*                              mr: 1,*/}
            {/*                           }}*/}
            {/*                        />*/}
            {/*                     ))}*/}
            {/*                  </Box>*/}
            {/*               </Box>*/}
            {/*            )}*/}
            {/*         </Box>*/}
            {/*      );*/}
            {/*   })}*/}
            {/*</Box>*/}

            <Box backgroundColor='#fafafa' height="100%" width="100%" px={2} borderRadius={2} my={2}>
               {
                  isAIGeneratedQuestionsEnabled && (
                     <>
                        <Box mt={2} sx={{flex: 1}}>
                           <Label
                              style={{fontSize: '12px', fontWeight: 'bold', color: '#000', marginBottom: '3px'}}
                              title="Prompt"
                           />
                           <Controller
                              {...register("notes")}
                              control={control}
                              render={({field}) => (
                                 <TextareaAutosize
                                    {...field}
                                    minRows={3}
                                    maxRows={6}
                                    sx={{
                                       width: "100%"
                                    }}
                                    style={{
                                       resize: "none",
                                    }}
                                 />
                              )}
                           />
                           <ErrorLabel
                              error={errors?.notes}
                              message={errors.notes?.message}
                           />
                        </Box>

                        <Box display="flex">
                           {
                              params ? <></> :
                                 <>
                                    <Divider sx={{my: 2}}/>
                                    <Box mt={2}>
                                       <Label
                                          style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}
                                          title="Number of questions"
                                       />
                                       <InputText
                                          // defaulValue={params && skill?.data?.skillName}
                                          // disabled={params && true}
                                          size="small"
                                          style={{width: '130px', backgroundColor: '#fff'}}
                                          {...register("questionsNumber")}
                                          value={watch("questionsNumber") === 0 ? "" : watch("questionsNumber")}
                                       />
                                       <ErrorLabel
                                          error={errors?.questionsNumber}
                                          message={errors.questionsNumber?.message}
                                       />
                                    </Box>
                                 </>
                           }

                           {
                              params || !isAIGeneratedQuestionsEnabled ? <></> :
                                 <Box mt={2} ml={2}>
                                    <Label
                                       style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}
                                       title="Number of interactions"
                                    />
                                    <InputText
                                       // defaulValue={params && skill?.data?.skillName}
                                       // disabled={params && true}
                                       size="small"
                                       style={{width: '150px'}}
                                       {...register("numberOfInteractionsPerQuestion")}
                                    />
                                    <ErrorLabel
                                       error={errors?.numberOfInteractionsPerQuestion}
                                       message={errors.numberOfInteractionsPerQuestion?.message}
                                    />
                                 </Box>
                           }
                        </Box>

                        <Divider sx={{my: 2}}/>

                        <>
                           {skillData?.data?.questions && (
                              <Box display="flex" alignItems="center">
                                 <CustomTypography
                                    title="AI generated questions"
                                    fontSize={12}
                                    fontWeight="bold"
                                    color="000"
                                 />
                                 {currentQuestions.length < 10 && (
                                    <IconButton
                                       disabled={isLoadingRegenerate}
                                       color="primary"
                                       style={{
                                          borderRadius: 10,
                                          marginLeft: 5,
                                       }}
                                       onClick={() => generateNewAIQuestion()}
                                    >
                                       {isLoadingRegenerate && triggeredBy === "generateNewAIQuestion" ? (
                                          <Loading size={20} style={{ mr: 1 }} />
                                       ) : (
                                          <ReplayIcon color="primary.main" />
                                       )}
                                       <CustomTypography
                                          title="Generated question AI"
                                          fontWeight={900}
                                          fontSize={12}
                                          color={"primary.main"}
                                       />
                                    </IconButton>
                                 )}
                              </Box>
                           )}
                           {params && skillData?.data?.questions && (
                              <Box
                                 sx={{
                                    background: "#fafafa",
                                    maxHeight: "500px",
                                    overflowY: "auto",
                                    mt: 3,
                                    mb: 3,
                                 }}
                              >
                                 {fieldsQuestion.map((question, index) => (
                                    <Box key={question.id}> {/* Usa el `id` único proporcionado por `useFieldArray` */}
                                       <Box
                                          sx={{
                                             display: "flex",
                                             justifyContent: "flex-end",
                                             alignItems: "center",
                                          }}
                                       >
                                          <IconButton
                                             disabled={isLoadingRegenerate}
                                             color="primary"
                                             style={{ borderRadius: 10 }}
                                             onClick={() => onRegenerateQuestion(index)}
                                          >
                                             {isLoadingRegenerate && indexQuestion === index ? (
                                                <Loading size={20} style={{ mr: 1 }} />
                                             ) : (
                                                <ReplayIcon color="primary.main" />
                                             )}
                                             <CustomTypography
                                                title="Regenerate"
                                                fontWeight={900}
                                                fontSize={12}
                                                color={"primary.main"}
                                             />
                                          </IconButton>
                                       </Box>
                                       <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
                                          <Box>
                                             <Label
                                                title={`Question ${index + 1}`}
                                                style={{
                                                   color: "#000",
                                                   fontWeight: "bold",
                                                   fontSize: "12px",
                                                }}
                                             />
                                             <Controller
                                                name={`questions.${index}.text`}
                                                control={control}
                                                render={({ field }) => (
                                                   <TextareaAutosize
                                                      {...field}
                                                      minRows={2}
                                                      maxRows={6}
                                                      style={{
                                                         resize: "none",
                                                         width: "700px",
                                                      }}
                                                   />
                                                )}
                                             />
                                             <ErrorLabel
                                                error={errors?.questions?.[index]?.text}
                                                message={errors?.questions?.[index]?.text?.message || ""}
                                             />
                                          </Box>
                                          <Box sx={{ position: "relative" }}>
                                             <Label
                                                title="Interactions"
                                                style={{
                                                   color: "#000",
                                                   fontWeight: "bold",
                                                   fontSize: "12px",
                                                   textAlign: "center",
                                                }}
                                             />
                                             <InputText
                                                style={{
                                                   backgroundColor: "#fff",
                                                   width: "100px",
                                                }}
                                                fullWidth={false}
                                                {...register(`questions.${index}.interactions`, { valueAsNumber: true })}
                                             />
                                             <Box
                                                sx={{
                                                   position: "absolute",
                                                   top: "100%",
                                                   left: 0,
                                                   color: "red",
                                                   fontSize: "12px",
                                                   mt: "2px",
                                                }}
                                             >
                                                <ErrorLabel
                                                   error={errors?.questions?.[index]?.interactions}
                                                   message={errors?.questions?.[index]?.interactions?.message || ""}
                                                />
                                             </Box>
                                          </Box>
                                          <IconButton
                                             onClick={() => removeQuestion(index)} // Asegúrate de que el índice es correcto
                                             style={{ cursor: "pointer" }}
                                          >
                                             <DeleteIcon color="error" />
                                          </IconButton>
                                       </Box>
                                       <QuestionHistory
                                          isVisible={questionHistoryIsVisible}
                                          data={skillData?.data}
                                          indexQuestion={index}
                                          onClick={() => onVisibleQuestionHistory(index)}
                                          indexHistory={indexHistory}
                                       />
                                    </Box>
                                 ))}
                              </Box>
                           )}
                        </>

                        {/*<>*/}
                        {/*   {skillData?.data?.questions &&*/}
                        {/*      <Box*/}
                        {/*         display="flex"*/}
                        {/*         alignItems="center"*/}
                        {/*      >*/}
                        {/*         <CustomTypography*/}
                        {/*            title="AI generated questions"*/}
                        {/*            fontSize={12}*/}
                        {/*            fontWeight="bold"*/}
                        {/*            color="000"*/}
                        {/*         />*/}

                        {/*         <IconButton*/}
                        {/*            disabled={isLoadingRegenerate}*/}
                        {/*            color="primary"*/}
                        {/*            style={{*/}
                        {/*               borderRadius: 10,*/}
                        {/*               marginLeft: 5*/}
                        {/*            }}*/}
                        {/*            onClick={() => generateNewAIQuestion()}*/}
                        {/*         >*/}
                        {/*            {isLoadingRegenerate && triggeredBy === "generateNewAIQuestion" ?*/}
                        {/*               <Loading size={20} style={{mr: 1}}/>*/}
                        {/*               :*/}
                        {/*               <ReplayIcon color="primary.main"/>*/}
                        {/*            }*/}
                        {/*            <CustomTypography*/}
                        {/*               title="Generated question AI"*/}
                        {/*               fontWeight={900}*/}
                        {/*               fontSize={12}*/}
                        {/*               color={"primary.main"}*/}
                        {/*            />*/}
                        {/*         </IconButton>*/}
                        {/*      </Box>*/}
                        {/*   }*/}
                        {/*   {params && skillData?.data?.questions &&*/}
                        {/*      <Box sx={{*/}
                        {/*         background: '#fafafa',*/}
                        {/*         maxHeight: '500px',*/}
                        {/*         overflowY: 'auto',*/}
                        {/*         mt: 3,*/}
                        {/*         mb: 3,*/}
                        {/*      }}>*/}
                        {/*         {fieldsQuestion.map((question, index) => (*/}
                        {/*            <>*/}
                        {/*               <Box*/}
                        {/*                  key={index + 1}*/}
                        {/*                  sx={{*/}
                        {/*                     display: 'flex',*/}
                        {/*                     justifyContent: 'flex-end',*/}
                        {/*                     alignItems: 'center',*/}
                        {/*                  }}*/}
                        {/*               >*/}
                        {/*                  <IconButton*/}
                        {/*                     disabled={isLoadingRegenerate}*/}
                        {/*                     color="primary"*/}
                        {/*                     style={{borderRadius: 10}}*/}
                        {/*                     onClick={() => onRegenerateQuestion(index)}*/}
                        {/*                  >*/}
                        {/*                     {isLoadingRegenerate && indexQuestion === index ?*/}
                        {/*                        <Loading size={20} style={{mr: 1}}/>*/}
                        {/*                        :*/}
                        {/*                        <ReplayIcon color="primary.main"/>*/}
                        {/*                     }*/}
                        {/*                     <CustomTypography*/}
                        {/*                        title="Regenerate"*/}
                        {/*                        fontWeight={900}*/}
                        {/*                        fontSize={12}*/}
                        {/*                        color={"primary.main"}*/}
                        {/*                     />*/}
                        {/*                  </IconButton>*/}
                        {/*               </Box>*/}
                        {/*               <Box display="flex" alignItems="center" justifyContent="space-between">*/}
                        {/*                  <Box*/}
                        {/*                     key={question.text}*/}
                        {/*                     sx={{*/}
                        {/*                        mt: 1,*/}
                        {/*                     }}*/}
                        {/*                  >*/}
                        {/*                     /!*<InputText*!/*/}
                        {/*                     /!*   fullWidth={false}*!/*/}
                        {/*                     /!*   {...register(`questions.${index}.text`)}*!/*/}
                        {/*
                        {/*                     <Label*/}
                        {/*                        title={`Question ${index + 1}`}*/}
                        {/*                        style={{*/}
                        {/*                           color: '#000',*/}
                        {/*                           fontWeight: 'bold',*/}
                        {/*                           fontSize: '12px'*/}
                        {/*                        }}*/}
                        {/*                     />*/}
                        {/*                     <Controller*/}
                        {/*                        key={question.text}*/}
                        {/*                        name={`questions.${index}.text`}*/}
                        {/*                        control={control}*/}
                        {/*                        render={({field}) => (*/}
                        {/*                           <TextareaAutosize*/}
                        {/*                              {...field}*/}
                        {/*                              minRows={3}*/}
                        {/*                              maxRows={6}*/}
                        {/*                              sx={{*/}
                        {/*                                 width: 700*/}
                        {/*                              }}*/}
                        {/*                              style={{*/}
                        {/*                                 resize: "none",*/}
                        {/*                              }}*/}
                        {/*                           />*/}
                        {/*                        )}*/}
                        {/*                     />*/}
                        {/*                  </Box>*/}
                        {/*                  <Box*/}
                        {/*                     key={index}*/}
                        {/*                     sx={{*/}
                        {/*                        mt: 1,*/}
                        {/*                     }}*/}
                        {/*                  >*/}
                        {/*                     <Label*/}
                        {/*                        title="Interactions"*/}
                        {/*                        style={{*/}
                        {/*                           color: '#000',*/}
                        {/*                           fontWeight: 'bold',*/}
                        {/*                           fontSize: '12px',*/}
                        {/*                           textAlign: 'center',*/}
                        {/*                        }}*/}
                        {/*                     />*/}
                        {/*                     <InputText*/}
                        {/*                        style={{*/}
                        {/*                           backgroundColor: '#fff',*/}
                        {/*                           width: '100px',*/}
                        {/*                        }}*/}
                        {/*                        fullWidth={false}*/}
                        {/*                        {...register(`questions.${index}.interactions`, {valueAsNumber: true})}*/}
                        {/*                     />*/}
                        {/*                  </Box>*/}
                        {/*               </Box>*/}
                        {/*               <QuestionHistory*/}
                        {/*                  isVisible={questionHistoryIsVisible}*/}
                        {/*                  data={skillData?.data}*/}
                        {/*                  indexQuestion={index}*/}
                        {/*                  onClick={() => onVisibleQuestionHistory(index)}*/}
                        {/*                  indexHistory={indexHistory}*/}
                        {/*               />*/}
                        {/*            </>*/}
                        {/*         ))}*/}
                        {/*      </Box>*/}
                        {/*   }*/}
                        {/*</>*/}
                     </>


                  )
               }

            </Box>

            <Box mt={2} width="102%">

               <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Label
                     style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}
                     title={
                        // params && isAIGeneratedQuestionsEnabled ? "Add extra-questions or notes" : "Add Question"
                        isAIGeneratedQuestionsEnabled ? "Custom Questions" :
                           !isAIGeneratedQuestionsEnabled && "Add questions"
                     }
                  />

                  <Box display="flex">
                     <Label
                        title="Interactions"
                        style={{
                           color: '#000',
                           fontWeight: 'bold',
                           fontSize: '12px',
                           textAlign: 'center',
                           marginRight: '20px',
                        }}
                     />

                     <Label
                        title="Delete"
                        style={{
                           color: '#000',
                           fontWeight: 'bold',
                           fontSize: '12px',
                           textAlign: 'center',
                        }}
                     />
                  </Box>
               </Box>

               {fields?.map((field, index) => (
                  <Box key={index + 1}>
                     <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        mt={2}
                        mb={4}
                     >
                        {/*<InputText*/}
                        {/*   size="small"*/}
                        {/*   fullWidth={true}*/}
                        {/*   {...register(`extraQuestions.${index}.value`)}*/}
                        {/*/>*/}
                        <Box maxHeight="100%" postion="relative">
                           <Controller
                              control={control}
                              name={`extraQuestions.${index}.text`}
                              render={({field}) => (
                                 <TextareaAutosize
                                    {...field}
                                    value={field.value || ''}
                                    onChange={(e) => field.onChange(e.target.value)}
                                    minRows={2}
                                    maxRows={4}
                                    sx={{
                                       width: 700,
                                    }}
                                    style={{
                                       resize: "none",
                                    }}
                                 />
                              )}
                           />
                           <Box
                              position="absolute"
                           >
                              <ErrorLabel
                                 error={errors?.extraQuestions?.[index]?.text}
                                 message={errors?.extraQuestions?.[index]?.text?.message || ""}
                              />
                           </Box>
                        </Box>

                        <Box
                           key={index}
                           display="flex"
                           position="relative"
                        >
                           <Box>
                              <InputText
                                 style={{
                                    backgroundColor: '#fff',
                                    width: '100px',
                                 }}
                                 {...register(`extraQuestions.${index}.interactions`, {valueAsNumber: true})}
                                 fullWidth={false}
                              />
                           </Box>
                           <IconButton
                              onClick={() => onDeleteExtraQuestionField(index)}
                              style={{cursor: 'pointer'}}
                           >
                              <DeleteIcon color="error"/>
                           </IconButton>
                           <Box
                              sx={{
                                 width: '100%',
                                 position: "absolute",
                                 top: "100%", // Posicionar justo debajo del campo
                                 left: 0,
                                 color: "red",
                                 fontSize: "12px",
                                 mt: "2px",
                                 height: "100%",
                              }}
                           >
                              <ErrorLabel
                                 error={errors?.extraQuestions?.[index]?.interactions}
                                 message={errors?.extraQuestions?.[index]?.interactions?.message || ""}
                              />
                           </Box>
                        </Box>
                     </Box>
                     {errors?.extraQuestions &&
                        <ErrorLabel
                           error={errors?.extraQuestions[index]}
                           message={errors.extraQuestions[index]?.value?.message}
                        />
                     }
                  </Box>
               ))}
            </Box>


            {errors?.extraQuestions &&
               <ErrorLabel
                  error={errors?.extraQuestions}
                  message={errors.extraQuestions.message}
               />
            }

            <Typography
               onClick={() => handleAddQuestions()}
               sx={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  color: 'primary.main',
                  mt: 2,
                  cursor: 'pointer'
               }}
            >
               Add another question
            </Typography>
         </Box>

         <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 7
         }}>

            {!params ?
               <CustomButton
                  title="GENERATE"
                  variant="contained"
                  style={{borderRadius: 50}}
                  size="medium"
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  type="submit"
               /> :

               <Box sx={{display: 'flex'}}>
                  <CustomButton
                     title="SAVE INTERVIEW"
                     variant="contained"
                     style={{borderRadius: 70, fontWeight: 'bold'}}
                     size="medium"
                     isDisabled={isLoadingUpdate}
                     isLoading={isLoadingUpdate}
                     type="submit"
                  />
                  <CustomButton
                     title="DISCART INTERVIEW"
                     variant="contained"
                     style={{borderRadius: 50, marginLeft: 20, fontWeight: 'bold'}}
                     size="medium"
                     color="error"
                     onPress={() => onDiscartChanges()}
                  />
               </Box>
            }
         </Box>
      </form>
   )
}
export default Form;

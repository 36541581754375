import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQuery, baseQueryWithReauth} from "./api";

export const apiSlice = createApi({
   reducerPath: 'api',
   baseQuery: baseQueryWithReauth,
   tagTypes: ['Interview', 'Candidate', 'SelfAssessment'],
   endpoints(builder) {
      return {
         auth: builder.mutation({
            query: (body) => ({
               url: '/auth',
               method: 'POST',
               body: body
            })
         }),
         getTasks: builder.query({
            query: ({_id, secret}) => ({
               url: `/interview?_id=${_id}&secret=${secret}`,
            }),
            providesTags: ['Interview'],
         }),
         uploadVideo: builder.mutation({
            query: ({_id, secret, formData}) => {
               return {
                  url: `/interview?_id=${_id}&secret=${secret}`,
                  method: 'POST',
                  body: formData,
                  formData: true
               }
            }
         }),
         completeInterview: builder.mutation({
            query: ({_id, secret, ...body}) => {
               return {
                  url: `/interview/finish-interview?_id=${_id}&secret=${secret}`,
                  method: 'POST',
                  body
               }
            }
         }),
         createSkill: builder.mutation({
            query: (body) => {
               return {
                  url: '/skill',
                  method: 'POST',
                  body: body,
               }
            }
         }),
         skills: builder.query({
            query: (params) => ({
               url: '/skill',
               params
            }),
         }),
         skill: builder.query({
            query: ({paramId}) => ({
               url: `/skill/${paramId}`
            }),
            providesTags: ['Candidate']
         }),
         updateSkill: builder.mutation({
            query: ({id, ...body}) => ({
               url: `/skill/${id}`,
               method: 'PUT',
               body
            })
         }),
         regenerateSkill: builder.mutation({
            query: ({id, ...body}) => ({
               url: `/skill/regenerate-skill/${id}`,
               method: 'POST',
            }),
         }),
         fetchUsers: builder.query({
            query: (params) => ({
               url: `/candidates/find`,
               params
            }),
         }),
         addCandidate: builder.mutation({
            query: ({_id, ...body}) => ({
               url: `/skill/${_id}/candidate`,
               method: 'POST',
               body
            }),
            invalidatesTags: ['Candidate'],
         }),
         statusSkill: builder.mutation({
            query: ({id, status}) => ({
               url: `/skill/${id}/${status}`,
               method: 'PUT'
            })
         }),
         forgotPassword: builder.mutation({
            query: (body) => ({
               url: `/auth/forgot-password`,
               method: 'PUT',
               body
            })
         }),
         restorePassword: builder.mutation({
            query: (body) => ({
               url: '/auth/restore-password',
               method: 'POST',
               body
            })
         }),
         fetchCandidates: builder.query({
            query: (params) => ({
               url: `/candidates`,
               params
            })
         }),
         fetchCandidatesByNameOrEmail: builder.query({
            query: (params) => ({
               url: `/candidates/find`,
               params
            })
         }),
         fetchCandidateById: builder.query({
            query: (id) => ({
               url: `/candidates/${id}`
            }),
            providesTags: ['Candidate']
         }),
         pdfTest: builder.mutation({
            query: ({id, ...body}) => ({
               url: `/skill/test/pdf/${id}`,
               method: 'POST',
               body,
               responseHandler: (response) => response.blob(),
               cache: "no-cache",
            }),
         }),
         createCandidate: builder.mutation({
            query: (body) => ({
               url: '/candidates',
               method: 'POST',
               body
            })
         }),
         interactionInterview: builder.mutation({
            query: ({_id, secret, questionIndex, ...body}) => ({
               url: `/interview/interaction?_id=${_id}&secret=${secret}&questionIndex=${questionIndex}`,
               method: 'POST',
               body
            }),
         }),
         uploadConversation: builder.mutation({
            query: ({_id, secret, questionIndex, ...body }) => ({
               url: `/interview/upload-conversation?_id=${_id}&secret=${secret}&questionIndex=${questionIndex}`,
               method: 'POST',
               body
            }),
            invalidatesTags: ['Interview'],
         }),
         signUp: builder.mutation({
            query: ({idSkill, ...body}) => ({
               url: `/auth/sign-up/${idSkill}`,
               method: 'POST',
               body: body
            }),
            invalidatesTags: ['SelfAssessment'],
         }),
         updateProfesionalInfo: builder.mutation({
            query: (body) => {
               const formData = new FormData();
               formData.append('yearsOfExperience', body.yearsOfExperience);
               formData.append('hourlyRate', body.hourlyRate);
               formData.append('languages', JSON.stringify(body.languages));
               formData.append('linkedInProfileURL', body.linkedInProfileURL);
               formData.append('disponibility', body.disponibility);
               formData.append('AdditionalSkills', JSON.stringify(body.skillsList));
               if (body.resume) {
                  formData.append('resume', body.resume);
               }
               return {
                  url: `/candidates/professional-info`,
                  method: 'PUT',
                  body: formData,
                  formData: true
               }
            }
         }),
         validateCode: builder.mutation({
            query: (body) => ({
               url: '/auth/verify-account-candidate',
               method: 'PUT',
               body: body
            })
         }),
         fetchSelfAssement: builder.query({
            query: (params) => ({
               url: '/interview/selfAssestments',
               params
            }),
            providesTags: ['SelfAssessment'],
         }),
         uploadSelfAssestment: builder.mutation({
            query: ({_id, secret, ...body}) => ({
               url: `/interview/upload-self-assessment?_id=${_id}&secret=${secret}`,
               method: 'POST',
               body
            }),
         }),
         uploadInterviewFeedBack: builder.mutation({
            query: ({_id, secret, ...body}) => ({
               url: `/interview/upload-interview-feedback?_id=${_id}&secret=${secret}`,
               method: 'POST',
               body
            })
         }),
         fetchInterviewTests: builder.query({
            query: (params) => ({
               url: `/interview/other-test`,
               params
            })
         }),
         fetchInterviewInfo: builder.query({
            query: (params) => ({
               url: `/interview/info`,
               params
            })
         }),
         fetchInterviewSettings: builder.query({
            query: ({_id, secret}) => ({
               url: `/interview/test-settings?_id=${_id}&secret=${secret}`,
            })
         })
      }
   }
})

export const {
   useAuthMutation,
   useLazyGetTasksQuery,
   useUploadVideoMutation,
   useCompleteInterviewMutation,
   useCreateSkillMutation,
   useSkillsQuery,
   useSkillQuery,
   useUpdateSkillMutation,
   useRegenerateSkillMutation,
   useFetchUsersQuery,
   useAddCandidateMutation,
   useStatusSkillMutation,
   useForgotPasswordMutation,
   useRestorePasswordMutation,
   useFetchCandidatesQuery,
   useFetchCandidatesByNameOrEmailQuery,
   useLazyFetchCandidateByIdQuery,
   usePdfTestMutation,
   useCreateCandidateMutation,
   useInteractionInterviewMutation,
   useUploadConversationMutation,
   useSignUpMutation,
   useUpdateProfesionalInfoMutation,
   useValidateCodeMutation,
   useLazyFetchSelfAssementQuery,
   useUploadSelfAssestmentMutation,
   useUploadInterviewFeedBackMutation,
   useLazyFetchInterviewTestsQuery,
   useLazyFetchInterviewInfoQuery,
   useLazyFetchInterviewSettingsQuery
} = apiSlice;
